// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'dev',
  production: false,
  region: 'NA',
  appUrl: 'https://your_development_environment/',

  apiBase: {
    url: 'https://api-dev.zonarsystems.net/gtcx-trip-api/v1/trip-zone'
  },

  coreEntityApiBase: {
    url: 'https://api-dev.zonarsystems.net'
  },

  userPreferencesApiBase: {
    url: 'https://api-dev.zonarsystems.net/alert/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'development',

  auth: {
    clientID: 'XwghDFbyCHTYHTZTSe6xloJIzETPmHeC',
    domain: 'zonar-dev.auth0.com',
    audience: 'http://apidev.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '82a93423-33f5-4003-8cc1-9a91d29acc6e',
    defaultZonarRole: 'c6f13b45-337d-43e7-a7f4-1a4d8a220b36',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_35f26fdc_6aa2_417f_ac93_d9b3305d80a8_c7771a1'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  //TODO: Setup your application's datadog app (see empty box readme)
  datadog: {
    applicationId: 'a9c074c5-b9e7-4c78-9ec5-9380eeaeba5a',
    clientToken: 'pub5725211aeea4a4f42e976e6b76562c75',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'dev'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'fr-FR', 'es-ES', 'it-IT'],
    defaultLanguage: 'en-US'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
